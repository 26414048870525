<template>
  <v-card
    elevation="0"
    rounded="lg"
    :class="
      $vuetify.breakpoint.mdAndUp
        ? 'py-12 px-15'
        : $vuetify.breakpoint.sm
        ? 'py-12 px-6'
        : 'py-10 px-2'
    "
    width="546"
    :color="
      $vuetify.breakpoint.mdAndUp
        ? $vuetify.theme.dark
          ? 'background_normal'
          : 'background_light'
        : 'transparent'
    "
  >
    <div class="mx-3">
      <v-overlay :value="overlay">
        <v-progress-circular indeterminate size="64" />
      </v-overlay>
      <v-form v-model="valid">
        <div
          class="d-flex flex-column align-center mb-2 py-5"
          :class="$vuetify.breakpoint.xs ? 'px-0' : 'px-3'"
          style="gap: 8px"
        >
          <span class="h3 bold onSurface_dark--text">
            {{ $t("signup.title") }}
          </span>
          <span
            class="regular onSurface_normal--text text-center"
            :class="$vuetify.breakpoint.mdAndUp ? 'title-2' : 'subtitle-1'"
          >
            {{ $t("signup.subtitle") }}
          </span>
        </div>
        <div class="d-flex flex-column my-7" style="gap: 26px">
          <div class="d-flex flex-column" style="gap: 12px">
            <div class="d-flex flex-column" style="gap: 10px">
              <div>
                <span class="title-2 regular onSurface_normal--text">
                  {{ $t("signup.cellNumber") }}
                </span>
                <span class="red--text"> * </span>
              </div>
              <v-select
                v-if="!disabled.phone"
                v-model="form.country"
                :rules="[rules.required]"
                :items="countries"
                item-text="name"
                item-value="name"
                required
                hide-details="auto"
                class="select"
                attach=".select"
                flat
                solo
                outlined
                height="53"
                :background-color="
                  $vuetify.theme.dark ? 'background_normal' : 'background_light'
                "
                :menu-props="{
                  maxHeight: 400,
                  offsetY: true,
                  contentClass: 'elevation-1',
                }"
                :disabled="verified || verifySended == true"
                @change="onSelectChange"
                @update:error="validation.country = !$event"
              >
                <template slot="label">
                  <span
                    class="title-2 regular"
                    :class="
                      $vuetify.theme.dark
                        ? 'onSurface_light--text'
                        : 'background_dark--text'
                    "
                  >
                    {{ $t("signup.regionLabel") }}
                  </span>
                </template>
                <template slot="append">
                  <v-icon size="16" color="onSurface_light">
                    mdi-chevron-down
                  </v-icon>
                </template>
                <template v-slot:selection="{ item }">
                  <div class="d-flex flex-column" style="gap: 6px">
                    <span class="primary--text subtitle-2 regular">
                      {{ $t("signup.regionLabel") }}
                    </span>
                    <div class="d-flex" style="gap: 6px">
                      <v-img max-width="22" :src="flagFileName(item)" />
                      <span class="text-capitalize onSurface_dark--text">
                        {{ $i18n.locale == "ko" ? item.name_kor : item.name }}
                        ({{ item.code }})
                      </span>
                    </div>
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <div
                    class="d-flex align-center"
                    style="gap: 6px; max-width: 100%"
                  >
                    <v-img
                      max-width="22"
                      max-height="16"
                      :src="flagFileName(item)"
                    />
                    <span class="text-capitalize onSurface_dark--text">
                      {{
                        $i18n.locale == "ko"
                          ? $vuetify.breakpoint.mdAndUp
                            ? `${item.name_kor} (${item.code}) (${item.name})`
                            : `${item.name_kor} (${item.code})`
                          : `${item.name} (${item.code})`
                      }}
                    </span>
                  </div>
                </template>
                <template v-slot:message="{ message }">
                  <span>{{ $t(message) }}</span>
                </template>
              </v-select>
            </div>
            <div class="d-flex" style="gap: 10px">
              <v-text-field
                v-model="form.phoneNumber"
                :rules="[rules.required, rules.phone]"
                required
                hide-details="auto"
                flat
                dense
                solo
                outlined
                single-line
                height="53"
                :background-color="
                  $vuetify.theme.dark ? 'background_normal' : 'background_light'
                "
                :disabled="
                  verified ||
                  verifySended == true ||
                  verifyLoading ||
                  disabled.phone
                "
                @update:error="validation.phoneNumber = !$event"
              >
                <template slot="label">
                  <span
                    class="title-2 regular"
                    :class="
                      $vuetify.theme.dark
                        ? 'onSurface_light--text'
                        : 'background_dark--text'
                    "
                  >
                    {{ $t("signup.phoneLabel") }}
                  </span>
                </template>
                <template v-slot:message="{ message }">
                  <span>{{ $t(message) }}</span>
                </template>
              </v-text-field>
              <v-btn
                v-if="!disabled.phone"
                color="primary"
                elevation="0"
                class="title-1 bold"
                height="53"
                :loading="verifyLoading"
                :disabled="
                  !(validation.phoneNumber && validation.country) ||
                  verifySended == true ||
                  verifyLoading ||
                  verified ||
                  disabled.phone
                "
                :style="verifySended == -1 ? 'transition: 1s ' : ''"
                @click="requestVerify()"
                >{{
                  verifySended == -1
                    ? $t("signup.reverifyBtn")
                    : $t("signup.verifyBtn")
                }}
              </v-btn>
            </div>
            <v-text-field
              v-if="!disabled.phone"
              v-model="form.verifyCode"
              :rules="[rules.required, rules.verify]"
              required
              type="number"
              hide-spin-buttons
              hide-details="auto"
              single-line
              flat
              dense
              solo
              outlined
              height="53"
              loader-height="2"
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              :disabled="!(verifySended && !verifyLoading) || verified"
              ref="verifier"
              @update:error="verifyInput($event)"
            >
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'
                  "
                >
                  {{ $t("signup.verifyLabel") }}
                </span>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
          </div>
          <div class="d-flex flex-column" style="gap: 10px">
            <div class="d-flex align-center" style="gap: 8px">
              <div>
                <span class="title-2 regular onSurface_normal--text">
                  {{ $t("signup.name") }}
                </span>
                <span class="red--text"> * </span>
              </div>
              <span class="subtitle-2 regular red--text">
                {{ $t("signup.nameWarning") }}
              </span>
            </div>
            <v-text-field
              v-model="form.name"
              :rules="[rules.required, rules.name]"
              required
              hide-details="auto"
              single-line
              flat
              dense
              solo
              outlined
              height="53"
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              :disabled="disabled.name"
              @update:error="validation.name = !$event"
            >
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'
                  "
                  >{{ $t("signup.nameLabel") }}</span
                >
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
          </div>
          <div v-if="!snsSignUp" class="d-flex flex-column" style="gap: 10px">
            <div>
              <span class="title-2 regular onSurface_normal--text">
                {{ $t("signup.password") }}
              </span>
              <span class="red--text"> * </span>
            </div>
            <v-text-field
              v-model="form.password"
              :rules="[rules.signUpPassword, rules.required]"
              required
              hide-details="auto"
              single-line
              flat
              dense
              solo
              outlined
              height="53"
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              :type="show1 ? 'text' : 'password'"
              @update:error="validation.password = !$event"
            >
              <template slot="append">
                <v-icon size="15" class="my-auto" @click="show1 = !show1">
                  {{ show1 ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
              </template>
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'
                  "
                >
                  {{ $t("signup.passwordLabel") }}
                </span>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
            <v-text-field
              v-model="form.passwordCheck"
              :rules="[rules.required, rules.passwordCheck]"
              required
              hide-details="auto"
              single-line
              flat
              dense
              solo
              outlined
              height="53"
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              :type="show2 ? 'text' : 'password'"
              @update:error="validation.passwordCheck = !$event"
            >
              <template slot="append">
                <v-icon size="15" class="my-auto" @click="show2 = !show2">
                  {{ show2 ? "mdi-eye-off" : "mdi-eye" }}
                </v-icon>
              </template>
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'
                  "
                >
                  {{ $t("signup.passwordCheckLabel") }}
                </span>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
          </div>
          <div class="d-flex flex-column" style="gap: 10px">
            <div class="d-flex align-center" style="gap: 8px">
              <div>
                <span class="title-2 regular onSurface_normal--text">
                  {{ $t("signup.email") }}
                </span>
                <span class="red--text"> * </span>
              </div>
              <span class="subtitle-2 regular red--text">
                {{ $t("signup.emailWarning") }}
              </span>
            </div>
            <v-text-field
              v-model="form.email"
              :rules="[rules.required, rules.email]"
              required
              hide-details="auto"
              single-line
              flat
              dense
              solo
              outlined
              height="53"
              :disabled="disabled.email"
              :background-color="
                $vuetify.theme.dark ? 'background_normal' : 'background_light'
              "
              @update:error="validation.email = !$event"
            >
              <template slot="label">
                <span
                  class="title-2 regular"
                  :class="
                    $vuetify.theme.dark
                      ? 'onSurface_light--text'
                      : 'background_dark--text'
                  "
                >
                  {{ $t("signup.emailLabel") }}
                </span>
              </template>
              <template v-slot:message="{ message }">
                <span>{{ $t(message) }}</span>
              </template>
            </v-text-field>
          </div>
        </div>
        <div class="d-flex flex-column" style="gap: 14px">
          <div
            @click="selectedAll = !selectedAll"
            class="d-flex align-center hover-pointer"
          >
            <v-icon
              :color="selectedAll ? 'primary' : 'onSurface_normal'"
              size="18"
            >
              {{
                selectedAll
                  ? "mdi-checkbox-marked"
                  : "mdi-checkbox-blank-outline"
              }}
            </v-icon>
            <span class="ml-1 onSurface_normal--text subtitle-1 regular">
              {{ $t("signup.acceptAll") }}
            </span>
          </div>
          <v-divider />
          <div class="d-flex flex-column" style="gap: 12px">
            <div
              @click="form.termsOfUse = !form.termsOfUse"
              class="d-flex align-center hover-pointer"
            >
              <v-icon
                :color="form.termsOfUse ? 'primary' : 'onSurface_normal'"
                size="18"
              >
                {{
                  form.termsOfUse
                    ? "mdi-checkbox-marked"
                    : "mdi-checkbox-blank-outline"
                }}
              </v-icon>
              <div>
                <span class="ml-1 onSurface_normal--text subtitle-1 regular">
                  {{ $t("signup.termsOfUse") }}
                </span>
                <span class="red--text"> * </span>
              </div>
            </div>
            <div class="d-flex align-center">
              <div
                @click="form.privacyPolicy = !form.privacyPolicy"
                class="d-flex align-center hover-pointer"
              >
                <v-icon
                  :color="form.privacyPolicy ? 'primary' : 'onSurface_normal'"
                  size="18"
                >
                  {{
                    form.privacyPolicy
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
                <div>
                  <span class="ml-1 onSurface_normal--text subtitle-1 regular">
                    {{ $t("signup.privacyPolicy") }}
                  </span>
                  <span class="red--text"> * </span>
                </div>
              </div>
              <v-spacer />
              <v-btn
                min-width="16"
                height="16"
                text
                class="pa-0"
                @click="
                  windowOpen(
                    'https://docs.madeall3d.com/660dd9c7-43aa-4824-9eef-e571d143dd2d'
                  )
                "
              >
                <v-icon size="16" color="onSurface_light">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
            <div class="d-flex align-center">
              <div
                @click="form.marketing = !form.marketing"
                class="d-flex align-center hover-pointer"
              >
                <v-icon
                  :color="form.marketing ? 'primary' : 'onSurface_normal'"
                  size="18"
                >
                  {{
                    form.marketing
                      ? "mdi-checkbox-marked"
                      : "mdi-checkbox-blank-outline"
                  }}
                </v-icon>
                <span class="ml-1 onSurface_normal--text subtitle-1 regular">
                  {{ $t("signup.marketing") }}
                </span>
              </div>
              <v-spacer />
              <v-btn
                min-width="16"
                height="16"
                text
                class="pa-0"
                @click="
                  windowOpen(
                    'https://docs.madeall3d.com/a7ed6b0b-92db-4494-adf9-c0a7491c9c1f'
                  )
                "
              >
                <v-icon size="16" color="onSurface_light">
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </div>
          </div>
        </div>
        <v-btn
          width="100%"
          height="53"
          color="primary"
          class="mt-11 mb-13"
          elevation="0"
          :disabled="
            !(
              valid &&
              this.form.privacyPolicy &&
              this.form.termsOfUse &&
              (verified || disabled.phone)
            )
          "
          @click="submit()"
        >
          <span class="title-1 bold">{{ $t("signup.signUpBtn") }}</span>
        </v-btn>
      </v-form>
    </div>
  </v-card>
</template>
<style scoped>
::v-deep .theme--light .v-input__slot fieldset {
  border-color: var(--v-background_dark-base);
}
::v-deep .theme--dark .v-input__slot fieldset {
  border-color: var(--v-onSurface_light-base);
}
.v-btn.v-btn--disabled {
  background-color: var(--v-background_dark-base) !important;
  color: #fefefe !important;
}
::v-deep
  .v-input--is-disabled.v-text-field--solo
  .v-input__control
  .v-input__slot {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
</style>
<script>
import API from "../../API/auth";
import countries from "@/assets/flags/flags.js";
import rules from "@/utils/rules.js";

export default {
  computed: {
    selectedAll: {
      set(val) {
        if (val == true) {
          this.form.termsOfUse = true;
          this.form.privacyPolicy = true;
          this.form.marketing = true;
        } else {
          this.form.termsOfUse = false;
          this.form.privacyPolicy = false;
          this.form.marketing = false;
        }
      },
      get() {
        return (
          this.form.termsOfUse && this.form.privacyPolicy && this.form.marketing
        );
      },
    },
  },
  data() {
    return {
      rules: Object.assign(rules, {
        passwordCheck: (v) => v == this.form.password || "rule.passwordCheck",
      }),
      validation: {
        country: false,
        phoneNumber: false,
        name: false,
        password: false,
        passwordCheck: false,
        email: false,
      },
      form: {
        country: undefined,
        phoneNumber: undefined,
        verifyCode: "",
        name: "",
        password: "",
        passwordCheck: "",
        email: "",
        termsOfUse: false,
        privacyPolicy: false,
        marketing: false,
      },
      disabled: {
        email: false,
        phone: false,
        name: false,
      },
      valid: false,
      countries: countries,
      phoneValid: false,
      verified: false,
      verifyLoading: false,
      verifySended: false, //-1 if reverifying
      verifyCodeAnswer: "",

      show1: false,
      show2: false,

      overlay: false,

      snsSignUp: false,
    };
  },
  mounted() {
    const user = this.$store.getters["auth/user/GET_USER"];
    if (user && user.phoneNumber && user.email && user.displayName) {
      this.$router.push({ name: "Home", query: { confirm: true } });
    } else {
      let params = this.$route.params;
      const provider = this.$route.query.provider;
      if (provider) {
        this.snsSignUp = true;
        // 비밀번호 입력창 없이
        this.$toasted.global.error(this.$i18n.t("auth.need_register_complete"));
        if (user) {
          params = {
            email: user.email,
            phone: user.phone,
            name: user.displayName,
            uid: user.uid,
          };
        }
        if (!params.uid) {
          this.$toasted.global.error(this.$i18n.t("signup.refreshError"));
          this.$router.push({ name: "Home", query: { confirm: true } });
          return;
        }
        if (params.email) {
          this.form.email = params.email;
          this.disabled.email = true;
        }
        if (params.phone) {
          this.form.phoneNumber = params.phone.replaceAll(/-|\s/g, "");
          this.disabled.phone = true;

          if (params.countryCode) {
            this.form.country = this.countries.find(
              (el) => el.code == params.countryCode
            );
            this.form.countryCode = this.form.country.code;
          } else {
            this.form.country = undefined;
            this.form.countryCode = "+82";
          }
        }
        if (params.name) {
          this.form.name = params.name;

          this.disabled.name = this.$store.getters["auth/user/GET_USER"]
            ? false
            : true;
        }
      }

      if (!this.form.country) {
        API.getCountry().then((res) => {
          let userCountry = countries.find(
            (el) => el.countryCode == res.data.countryCode
          );
          this.form.country = userCountry.name;
        });
      }
    }
  },
  methods: {
    /**
     * 국가 선택을 바탕으로 자동으로 페이지 언어를 변환합니다.
     */
    onSelectChange(select) {
      this.$i18n.locale = select.includes("korea") ? "ko" : "en";
    },
    /**
     * 국가 플래그 이미지 파일명을 생성합니다.
     * @param {Object} item 국가 정보 객체
     * @returns {String} 국가 플래그 이미지의 경로
     */
    flagFileName(item) {
      let filename = item.flag || item.name;
      filename = filename.replaceAll(" ", "-");
      return require("@/assets/flags/" + filename + ".svg");
    },
    async requestVerify() {
      if (this.form.phoneNumber.match(/^\d{2,3}-\d{3,4}-\d{4}$/)) {
        this.form.phoneNumber = this.form.phoneNumber.replaceAll(/-/g, "");
      }
      if (this.form.phoneNumber.match(/^\d{9,11}$/)) {
        const country = this.countries.find(
          (el) => el.name == this.form.country
        );
        if (country == undefined) {
          return;
        }
        //연락처 countrycode랑 따로 뗌
        this.form.countryCode = country.code;
      }
      this.verifyLoading = true;

      const phone = this.form.phoneNumber;
      const authPhoneNumber =
        this.form.countryCode +
        (phone.startsWith("0") ? phone.slice(1) : phone);

      //전화번호 중복을 대비해 추가 검증
      const res = await this.$axios.get("auth/check-user-existence", {
        params: { phone: authPhoneNumber },
      });

      if (res.status != 204) {
        if (res.status != 200) {
          this.$toasted.global.error(
            this.$i18n.t("auth.unknown_error_occured")
          );
        }

        const uid = res.data.uid;
        if (uid.includes("kakao")) {
          this.$toasted.global.error(this.$i18n.t("auth.kakao_account_exist"));
        } else if (uid.includes("naver")) {
          this.$toasted.global.error(this.$i18n.t("auth.naver_account_exist"));
        } else if (
          res.data.providerData &&
          res.data.providerData.length > 0 &&
          res.data.providerData[0].providerId == "google.com"
        ) {
          this.$toasted.global.error(this.$i18n.t("auth.google_account_exist"));
        } else {
          this.$toasted.global.error(this.$i18n.t("auth.phone_account_exist"));
        }

        if (this.$store.getters["auth/user/GET_USER"]) {
          await this.$firebase
            .auth()
            .currentUser.delete()
            .catch((e) => {
              console.log(e);
              this.$router.push({ name: "Login", query: { confirm: true } });
            });
        }
        this.$router.push({ name: "Login", query: { confirm: true } });
        API.rebootChannelIo();
        return;
      }

      this.$axios
        .patch("/notice/RequestVerify", {
          phoneNumber: authPhoneNumber,
        })
        .then((res) => {
          if (res.status != 200) {
            this.$toasted.global.error(this.$t("signup.serverError"));
          }

          this.verifyCodeAnswer = res.data.code;

          // 로컬 테스트용 인증번호
          // console.log(this.verifyCodeAnswer);

          this.verifySended = true;

          this.verifyLoading = false;
          this.$toasted.global.notice(this.$t("signup.verifySended"));

          setTimeout(() => {
            this.verifySended = -1;
          }, 2000);
        })
        .catch(() => {
          this.$toasted.global.error(this.$t("signup.serverLost"));
          this.verifyLoading = false;
        });
    },

    verifyInput(error) {
      if (error) return;
      if (this.form.verifyCode.length != 6) return;
      if (this.form.verifyCode == this.verifyCodeAnswer) {
        this.verified = true;
        this.$toasted.global.notice(this.$t("signup.verified"));
      } else {
        this.$toasted.global.error(this.$t("signup.notVerified"));
      }
    },

    windowOpen(externalLink) {
      window.open(externalLink);
    },
    sendSignUpEvent() {
      this.$gtag.event("sign_up", {
        event_category: "Engagement",
        event_label: "SignUp",
        value: 1,
      });
    },
    submit() {
      //countryCode 붙여서 보냄
      let param = {
        email: this.form.email,
        password: this.form.password,
        name: this.form.name,
        countryCode: this.form.countryCode,
        phoneNumber: this.form.phoneNumber,
        authPhoneNumber: this.form.countryCode + this.form.phoneNumber.slice(1),
        rule: this.form.termsOfUse,
        userInfo: this.form.privacyPolicy,
        marketing: this.form.marketing,
      };

      this.overlay = true;

      if (this.$store.getters["auth/user/GET_USER"]) {
        const user = this.$store.getters["auth/user/GET_USER"];
        param.uid = user.uid;
        param.photoURL = user.photoURL;
        API.finishSignUpWithGoogle(param)
          .then(() => {
            this.overlay = false;
            this.sendSignUpEvent();
          })
          .catch(() => {
            this.overlay = false;
          });
      } else if (this.$route.params.uid) {
        // {
        //   uid: uid,
        //   email: email,
        //   phoneNumber: phone,
        //   name: "보류",
        //   userInfo: true,
        //   rule: true,
        //   marketing: false,
        // }
        //params 보낼 양식

        //SNS 로그인에서 리다이렉트로 들어왔을때

        param.uid = this.$route.params.uid;
        param.photoURL = this.$route.params.photoURL;

        if (param.uid.includes("kakao:")) {
          API.signUpWithKakao(param);
        } else if (param.uid.includes("naver:")) {
          API.signUpWithNaver(param);
        }
        return;
      } else {
        param.photoURL = 
        API.signUpWithPhone(param)
          .then(() => {
            // console.log(this.$store.getters["auth/user/GET_USER"].uid);
            // setTimeout(() => {
            //   this.overlay = false;
            //   this.$router.push({ name: "Home" }).then(() => {
            //     window.scrollTo(0, 0);
            //     this.$toasted.global.notice("가입을 환영합니다!");
            //     this.sendSignUpEvent();
            //   });
            // }, 500);
          })
          .catch((err) => {
            console.error("SIGNUP error: ", err);
            this.overlay = false;
          });
      }
    },
  },
};
</script>
