<template>
  <v-container
    fill-height
    fluid
    class="pa-0 mx-0"
    :class="$vuetify.theme.dark ? 'background_light' : 'background_normal'"
  >
    <sign-up
      class="mx-auto"
      :class="$vuetify.breakpoint.mdAndUp ? 'my-15' : 'my-0'"
    />
  </v-container>
</template>

<style></style>
<script>
if (!window.Kakao.isInitialized()) {
  window.Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY_WEB);
  window.Kakao.isInitialized();
}
import SignUp from "@/components/Authorization/signUp";
import setMeta from "@/utils/setMeta";

export default {
  components: {
    SignUp,
  },
  data() {
    return {};
  },
  methods: {},

  created() {
    setMeta({
      title: "회원가입",
      description: "메이드올의 온라인 메이커 플랫폼입니다.",
      image:
        "https://firebasestorage.googleapis.com/v0/b/madeall.appspot.com/o/Assets%2FOG%2Flogin.png?alt=media&token=171e59e8-a13a-43eb-83f1-5f2b0a5629a6",
    });
  },
};
</script>
